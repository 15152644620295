<template>
  <div class="government">
    <van-cell
      v-for="(item, index) in formData"
      :key="index"
      :border="false"
      :title="item.title"
      :url="item.url"
      :title-style="titleStyle"
      is-link
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      titleStyle: {
        "padding-right": "1.25rem",
        "white-space": "nowrap",
        "font-size": "0.8125rem",
        overflow: "hidden",
        "text-overflow": "ellipsis"
      },
      formData: [
        {
          title: "赣州经开区劳动监察局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201022000&flag=gj"
        },
        {
          title: "教体服务中心",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201025000&flag=gj"
        },
        {
          title: "赣州市自然资源局赣州经济技术开发区分局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201026000&flag=gj"
        },
        {
          title: "赣州市市场监督管理局赣州经济技术开发区分局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201028000&flag=gj"
        },
        {
          title: "赣州市生态环境局赣州经济技术开发区分局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201030000&flag=gj"
        },
        {
          title: "赣州经开区公安分局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201032000&flag=gj"
        },
        {
          title: "赣州市公安局交通警察支队开发区大队",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201033000&flag=gj"
        },
        {
          title: "赣州市司法局赣州经济技术开发区分局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201036000&flag=gj"
        },
        {
          title: "赣州经开区消防救援大队",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201037000&flag=gj"
        },
        {
          title: "赣州经开区党群工作部",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201002000&flag=gj"
        },
        {
          title: "赣州经开区经济发展局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201003000&flag=gj"
        },
        {
          title: "赣州经开区招商局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201004000&flag=gj"
        },
        {
          title: "赣州经开区行政审批局（企业服务和工信局）",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201005000&flag=gj"
        },
        {
          title: "赣州经开区财政局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201006000&flag=gj"
        },
        {
          title: "赣州经开区住房和建设局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201007000&flag=gj"
        },
        {
          title: "赣州经开区城市管理局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201008000&flag=gj"
        },
        {
          title: "赣州经开区农业农村工作办公室",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201009000&flag=gj"
        },
        {
          title: "赣州经开区社会事务管理局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201010000&flag=gj"
        },
        {
          title: "赣州经开区房地产管理所",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201015000&flag=gj"
        },
        {
          title: "赣州经开区公用事业服务中心",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201016000&flag=gj"
        },
        {
          title: "赣州经开区计划生育服务站",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201017000&flag=gj"
        },
        {
          title: "人社保障服务中心",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201018000&flag=gj"
        },
        {
          title: "国家税务总局赣州经济技术开发区税务局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201019000&flag=gj"
        },
        {
          title: "赣州市城市管理综合行政执法支队赣州经济技术开区分局",
          url:
            "http://gzkfq.jxzwfww.gov.cn/jxzw/czbm/index.do?webId=112&orgCode=360703000201021000&flag=gj"
        }
      ]
    };
  }
};
</script>
<style lang="less" scoped>
.government {
  width: 100vw;
  padding: 0.625rem 0 0;
  box-sizing: border-box;
  color: #666;
  van-cell {
    height: 4.375rem;
  }
}
</style>
